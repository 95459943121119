<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-truck</v-icon>
          ASIGNAR TRANSPORTISTA
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-autocomplete
            label="Guia"
            class="mt-6"
            v-model="busCabeceraId"
            :items="guiasCoord"
            item-text="NOM_GUIA"
            item-value="CABECERA_ID"
            @change="cargarLista()"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-text-field
            type="date"
            v-model="fechaArriboEstimada"
            label="Fecha llegada estimada"
            class="mt-2 mb-0 pb-0"
          >
            <template v-slot:append>
              <v-btn
                icon
                title="Modificar fecha de llegada"
                @click="guardarFechaTransporte"
                ><v-icon left>mdi-content-save</v-icon></v-btn
              >
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="mt-3 mb-0 pb-0"
            label="buscar"
            v-model="searchCargaBod"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar>
        <v-btn small color="blue" dark @click="imprimirLote"
          ><v-icon left>mdi-printer</v-icon>Etiquetas</v-btn
        >
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :search="searchCargaBod"
        :loading="loadingTable"
        class="elevation-1"
        v-model="itemsSel"
        item-key="ID"
        show-select
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn icon small color="green" @click="abrirDialogCargaDim(item)">
            <v-icon>mdi-move-resize</v-icon>
          </v-btn>

          <v-btn icon small color="red" @click="descargarEtiqueta(item)">
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- dialogo carga dimensiones -->
    <v-dialog v-model="dialogCargaDim" max-width="700px">
      <v-card>
        <v-toolbar flat color="blue" height="40" dark>
          <v-card-title class="mx-0 mt-1 px-0">
            <v-icon class="mb-1" left>mdi-truck</v-icon>
            Datos cajas
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formCargaBodega">
              <v-row>
                <v-col class="pt-2 pb-0" cols="12">
                  <v-text-field
                    tabindex="-1"
                    readonly
                    v-model="datosCargaDim.FINCA"
                    label="Finca"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    tabindex="-1"
                    class="py-0"
                    readonly
                    v-model="datosCargaDim.CLIENTE_FINAL"
                    label="Cliente"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    tabindex="-1"
                    class="py-0"
                    readonly
                    v-model="datosCargaDim.TIPO_CAJA"
                    label="Tipo de caja"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    tabindex="-1"
                    class="py-0"
                    readonly
                    v-model="datosCargaDim.TRANSP_NOMBRE"
                    label="Transportista"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    tabindex="-1"
                    class="py-0"
                    readonly
                    v-model="datosCargaDim.FECHA_TRANSPORTE"
                    label="Fecha de transporte"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    id="piezas"
                    ref="txtPiezas"
                    tabindex="0"
                    :rules="requiredRule"
                    v-model="datosCargaDim.PIEZAS"
                    label="Piezas"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosCargaDim.ANCHO"
                    label="Ancho"
                    tabindex="1"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosCargaDim.ALTO"
                    label="Alto"
                    tabindex="2"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosCargaDim.LARGO"
                    label="Largo"
                    tabindex="3"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="guardarCargaDim">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;&nbsp;
            <v-btn small @click="dialogCargaDim = !dialogCargaDim">
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "CargaBodega",
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "MAWB", value: "AWB" },
      { text: "HAWB", value: "NUM_GUIA_FINCA" },
      { text: "FINCA", value: "FINCA" },
      { text: "CLIENTE FINAL", value: "CLIENTE_FINAL" },
      { text: "TIPO DE CAJA", value: "CAJA_COD" },
      { text: "PIEZAS", value: "PIEZAS" },
      { text: "TRANSPORTISTA", value: "TRANSP_NOMBRE" },
      { text: "DIA", value: "DIA_TRANSP" },
    ],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    emailRules: [
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Ingrese un Email válido",
    ],
    lista: [],

    options: {},

    currentPage: 1,
    pageCount: 1,
    itemsPerPage: 20,

    dialogTransportista: false,
    datosTransportista: {
      TRANSP_ID: "",
      TRANSP_NOMBRE: "",
      ACTIVO: 1,
    },

    dialogCargaDim: false,
    datosCargaDim: { FINCA: "", CLIENTE: "", CLIENTE_FINAL: "" },
    fechaArriboEstimada: "",

    nuevoTransp: false,
    busCabeceraId: "",
    search: "",

    guiasCoord: [],
    itemsSel: [],
    searchCargaBod: "",
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user"]),
    ...mapGetters("access", [""]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    validarEmail(email) {
      if (email == "") {
        return [true];
      }
      if (email == null) {
        return [true];
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },

    cargarDatos() {
      this.setLoadingTable(true);
      this.setUrl("api/datos-carga-recibida");
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          //console.log(res);
          this.guiasCoord = res.data.guiasCoord;
          this.pageCount = 10;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-carga-bodega");
      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,

          CABECERA_ID: this.busCabeceraId,
        },
      })
        .then((res) => {
          //console.log(res);
          this.lista = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    editarTransportista(transpId) {
      this.nuevoTransp = false;
      this.abrirDialogTransportista(transpId);
    },
    abrirDialogTransportista(transpId) {
      this.setUrl("api/transportista");
      this.requestApi({
        method: "GET",
        data: {
          TRANSP_ID: transpId,
        },
      })
        .then((res) => {
          this.datosTransportista = res.data[0];
          this.dialogTransportista = true;
        })
        .then(() => {});
    },

    eliminarTransportista(transportistaId) {
      Vue.swal({
        html: "Está seguro de eliminar el transportista ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.isConfirmed) {
            this.setUrl("api/borrar-transportista");
            this.requestApi({
              method: "POST",
              data: {
                TRANSP_ID: transportistaId,
              },
            }).then((res) => {
              //console.log(res);
              this.cargarLista();
              this.alertNotification({ param: { html: res.data.msg } });
            });
          }
        }
      });
    },
    guardarFechaTransporte() {
      if (this.fechaArriboEstimada == "") {
        return false;
      }

      this.setUrl("api/guardar-fecha-transporte");
      this.requestApi({
        method: "POST",
        data: {
          FECHA_TRANSPORTE: this.fechaArriboEstimada,
          CABECERA_ID: this.busCabeceraId,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarLista();
          this.dialogTransportista = false;
        })
        .then(() => {});
    },
    abrirDialogCargaDim(item) {
      this.cargarDatosCargaDim(item);
      this.dialogCargaDim = true;
    },
    cargarDatosCargaDim(item) {
      this.setUrl("api/datos-carga-bodega");
      this.requestApi({
        method: "GET",
        data: {
          ID: item.ID,
        },
      }).then((res) => {
        this.datosCargaDim = res.data.datos_carga;

        //this.alertNotification({ param: { html: res.data.msg } });
      });
    },
    modificarCargaDim() {
      this.setUrl("api/modificar-carga-bodega");
      this.dialogCargaDim = true;
      this.requestApi({
        method: "POST",
        data: {
          datos_carga: this.datosCargaDim,
        },
      }).then((res) => {
        this.cargarDatosCargaDim(this.datosCargaDim);
        this.cargarLista();
        this.alertNotification({ param: { html: res.data.msg } });
      });
    },
    guardarCargaDim() {
      this.modificarCargaDim();
    },
    descargarEtiqueta(item) {
      this.setUrl("api/etiqueta-carga-bodega");

      this.requestApi({
        method: "POST",
        data: {
          ID: item.ID,
        },
      }).then((res) => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + res.data.datos;
        a.download = res.data.nombreArchivo;

        a.click();
        this.alertNotification({ param: { html: res.data.msg } });
      });
    },
    imprimirLote() {
      this.setUrl("api/etiqueta-carga-bodega");
      let listaImp = [];
      console.log(this.itemsSel);
      for (let i = 0; i < this.itemsSel.length; i++) {
        listaImp.push(this.itemsSel[i].ID);
      }
      if (listaImp.length == 0) {
        alert("Seleccione al menos una etiqueta");
        return false;
      }
      this.requestApi({
        method: "POST",
        data: {
          IDS: listaImp,
        },
      }).then((res) => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + res.data.datos;
        a.download = res.data.nombreArchivo;
        a.click();
        this.itemsSel = [];

        this.alertNotification({ param: { html: res.data.msg } });
      });
    },
    handlePageChange() {
      this.cargarLista();
    },
    onkeyup(e) {
      //alert(e.keyCode);
      //C
      if (e.altKey && e.keyCode == 67) {
        alert("CANCELAR");
        this.dialogCargaDim = false;
      }
      //G
      if (e.altKey && e.keyCode == 71) {
        alert("GUARDAR");
      }
      //B
      if (e.altKey && e.keyCode == 66) {
        alert("BORRAR");
      }
      //M
      if (e.altKey && e.keyCode == 77) {
        alert("MODIFICAR");
      }
    },
  },

  mounted() {
    document.title = "Asignar Transportista";

    this.setTitleToolbar("Asignar transportista");
    this.cargarDatos();
    this.cargarLista();
  },

  created() {
    document.onkeyup = this.onkeyup;
  },
};
</script>
